import { Auth } from 'aws-amplify'

export const isBrowser = () => typeof window !== 'undefined'

export const handleLogin = async (userLogin) => {
  try {
    const user = await Auth.signIn(userLogin.email, userLogin.password)
    setUser(user)
    return 200
  } catch (error) {
    return 'Usuário e/ou Senha Incorretos!'
  }
}

export const signUp = async (userData) => {
  try {
    const { user } = await Auth.signUp({
      username: userData.email,
      password: userData.password,
      attributes: {
        phone_number: '+55' + userData.telefone.replace(/\s|-|\(|\)|/gi, ''),
        name: userData.nome,
        address: userData.cidade + ' - ' + userData.estado,
        'custom:cnpj': userData.cnpj.replace(/\.|\/|-/gi, ''),
        'custom:cep': userData.cep.replace(/\.|\/|-/gi, '')
      }
    })
    console.log(user)
    return 200
  } catch (error) {
    console.log('error signing up:', error)
    return error
  }
}

export const confirmSignUp = async (email, code) => {
  console.log(email, code)
  try {
    await Auth.confirmSignUp(email, code)
    return 200
  } catch (error) {
    console.log('error confirming sign up', error)
    return error
  }
}

export const resendConfirmationCode = async (email) => {
  try {
    await Auth.resendSignUp(email)
    return 200
  } catch (err) {
    return err
  }
}

export const forgotPassword = async (user) => {
  try {
    await Auth.forgotPassword(user.email)
    return 200
  } catch (err) {
    return err
  }
}

export const forgotPasswordSubmit = async (user) => {
  try {
    await Auth.forgotPasswordSubmit(user.email, user.code, user.newPassword)
    return 200
  } catch (err) {
    return err
  }
}

export const changePassword = (oldPassword, newPassword) => {
  console.log(oldPassword, newPassword)
  return Auth.currentAuthenticatedUser()
    .then(async user => {
      try {
        console.log('user?', user)
        await Auth.changePassword(user, oldPassword, newPassword)
        return 200
      } catch (err) {
        console.log(err)
        return err
      }
    })
    .catch(err => console.log(err))
}

export const changeAttributes = async (attributes) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, attributes)
    const newUser = await Auth.currentAuthenticatedUser()
    setUser(newUser)
    return 200
  } catch (err) {
    return 400
  }
}

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('userAuth')
    ? JSON.parse(window.localStorage.getItem('userAuth'))
    : {}

const setUser = user => {
  if (isBrowser()) {
    window.localStorage.removeItem('userAuth')
    window.localStorage.setItem('userAuth', JSON.stringify(user))
  }
}

export const isLoggedIn = () => {
  const user = getUser()
  return user.attributes ? !!user.attributes.name : false
}

export const logout = async callback => {
  await Auth.signOut()
  setUser({})
  callback()
}
