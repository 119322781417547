import React, { useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// CSS, SCSS
import '../styles/solicite-sua-coleta.scss'

// components
// import Pagina from '../../components/Pagina/index'

import { forgotPassword, forgotPasswordSubmit, isBrowser } from '../../services/auth'

const EsqueciSenha = () => {
  const [formState, setFormState] = useState('')
  const [hasCode, setHasCode] = useState('')
  const [errorMessage, setErrorMessage] = useState(false)
  const intl = useIntl()

  const setInput = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const data = useStaticQuery(
    graphql`{
  pagina: contentfulPagina(path: {eq: "/esqueci-a-senha/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  img: file(relativePath: {eq: "man.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 700, height: 840, layout: CONSTRAINED)
    }
  }
}
`)
  console.log(data.pagina)

  const handleSubmit = () => {
    forgotPassword(formState)
      .then(res => {
        if (res === 200) {
          setErrorMessage(false)
          setHasCode(<>{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.submit.has_code' })}</>)
        } else {
          setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.submit.error_message' })}</>)
        }
      })
  }

  const handleCodeSubmit = () => {
    if (validaForm()) {
      forgotPasswordSubmit(formState)
        .then(res => {
          if (res === 200) {
            setErrorMessage(false)
            setHasCode(<>{parse(intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.code_submit.has_code' }))}</>)
            setTimeout(() => {
              let url = isBrowser() && localStorage.getItem('originPath')
              url = url === null ? '/coleta-de-oleo-lubrificante-usado/' : url
              navigate(url)
            }, 3000)
          } else {
            setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.code_submit.error_message' })}</>)
          }
        })
    }
  }

  const validaForm = () => {
    if (Object.keys(formState).length !== 3) {
      setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.valid_form.error_message1' })}</>)
    } else if (formState.newPassword.length < 8) {
      setErrorMessage(<>{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.valid_form.error_message2' })}</>)
    } else {
      return true
    }
  }

  return <>
    {/* <Pagina pagina={data.pagina} /> */}
    <section className="container-fluid full m-0">
      <div className="row">
        <div className="col-lg-4 p-0">
          <GatsbyImage
            image={data.img.childImageSharp.gatsbyImageData}
            alt=""
            className="w-100 h-100" />
        </div>
        <div className="col-lg-8 bg-lwart-lightblue2 p-0">
          <div className="container" style={{ padding: '35px' }}>
            <p className="text-white text-uppercase font-Spinnaker font-size-125">{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.section_esqueci_senha.p1' })}</p>
            <p className="font-Spinnaker font-size-2 text-lwart-green3"><b>{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.section_esqueci_senha.p2' })}</b></p>
            <div className="col-lg-10 p-0">
              <form className="form-coleta p-0 w-100 mw-100">
                <div className="row">
                  <div className="col-lg-10">
                    {
                      hasCode ? (
                        <>
                          <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.section_esqueci_senha.label1' })}
                            <input className="mb-4 w-100" value={formState.email} disabled/>
                          </label>
                          <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.section_esqueci_senha.label2' })}
                            <input className="mb-4 w-100" name="code" onChange={(event) => setInput(event)} />
                          </label>
                          <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.section_esqueci_senha.label3' })}
                            <input className="mb-4 w-100" name="newPassword" type="password" onChange={(event) => setInput(event)} />
                          </label>
                        </>
                      ) : (
                        <label className="text-white font-Spinnaker">{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.section_esqueci_senha.label4' })}
                          <input className="mb-4 w-100" name="email" onChange={(event) => setInput(event)} />
                        </label>
                      )
                    }
                  </div>
                  <div className="col-md-10">
                    {
                      !!errorMessage && (
                        <div className="d-flex bg-lwart-darkblue align-items-center justify-content-center py-3 px-4 my-3 rounded ">
                          <p className="bg-danger rounded-circle text-white text-center align-self-center mb-0" style={{ width: '25px', height: '25px' }} >!</p>
                          <p className="text-center text-white mb-0 font-weight-bold w-75">
                            {errorMessage}
                          </p>
                        </div>
                      )
                    }
                    {
                      !!hasCode && (
                        <div className="d-flex align-items-center justify-content-center bg-lwart-green rounded p-2 my-3">
                          <p
                            className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-text-center"
                            style={{ width: '45px', height: '45px' }} >✔</p>
                          <p className="font-weight-bold text-white text-center font-size-08 m-0">
                            {hasCode}
                          </p>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="col-lg-10 p-0">
                  {
                    hasCode ? (
                      <button
                        type="button"
                        className="text-white btn-entrar btn w-100 py-2 mb-3 font-Spinnaker"
                        onClick={() => handleCodeSubmit()}
                      >{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.section_esqueci_senha.btn1' })}</button>
                    ) : (
                      <button
                        type="button"
                        className="text-white btn-entrar btn w-100 py-2 mb-3 font-Spinnaker"
                        onClick={() => handleSubmit()}
                      >{intl.formatMessage({ id: 'paginas.area_cliente.esqueci_a_senha.section_esqueci_senha.btn2' })}</button>
                    )
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default EsqueciSenha
